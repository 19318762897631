import { BsTwitter, BsInstagram } from "react-icons/bs";
import { FaGithub, FaLinkedin } from "react-icons/fa";

/* const SocialMedia = () => {
  return (
    <div className="app__social">
      <div>
        <FaLinkedin />
      </div>
      <div>
        <FaGithub />
      </div>
    </div>
  );
}; */

const SocialMedia = () => {
  return (
    <div className="app__social">
      <a
        href="https://www.linkedin.com/in/nikita-masalov/"
        target="_blank"
        rel="noreferrer"
      >
        <FaLinkedin />
      </a>

      <a href="https://github.com/GIGI-QUEEN" target="_blank" rel="noreferrer">
        <FaGithub />
      </a>
    </div>
  );
};

export default SocialMedia;
