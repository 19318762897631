import email from "../assets/email.png";
import mobile from "../assets/mobile.png";
import react from "../assets/react.svg";
import redux from "../assets/redux.png";
import sass from "../assets/sass.png";
import mu5 from "../assets/mu5.png";
import typescript from "../assets/typescript.svg";

import about01 from "../assets/about01.png";
import about02 from "../assets/about02.png";
import about03 from "../assets/about03.png";
import about04 from "../assets/about04.png";

import profile from "../assets/profile.png";
import circle from "../assets/circle.svg";
import logo2 from "../assets/logo2.png";
import logo3 from "../assets/logo3.png";

export default {
  email,
  mobile,
  react,
  redux,
  sass,
  about01,
  about02,
  about03,
  about04,
  profile,
  circle,
  logo2,
  logo3,
  mu5,
  typescript,
};
